//export const SITE_URL="https://localhost:7248/Public/";  //Local
//export const SITE_URL="https://webapi-dev.segoma.com/Service/Public/";  //Dev
export const SITE_URL="https://webapi.segoma.com/Service/Public/";  //Prod

export const DiamondClarity = [
    { name: 'FL', value: 1 },
    { name: 'IF', value: 2 },
    { name: 'VVS1', value: 3 },
    { name: 'VVS2', value: 4 },
    { name: 'VS1', value: 5 },
    { name: 'VS2', value: 6 },
    { name: 'SI1', value: 7 },
    { name: 'SI2', value: 8 },
    { name: 'I1', value: 9 },
    { name: 'I2', value: 10 },
    { name: 'SI3', value: 11 },
    { name: 'I3', value: 12 },
    { name: 'CO', value: 13 },
    { name: 'P1', value: 14 },
    { name: 'P2', value: 15 },
    { name: 'P3', value: 16 },
  ];

export const ObjectType = [
    { name: 'Diamond', value: 0 },
    { name: 'Gemstone', value: 1 },
    { name: 'Jewelry', value: 2 },
];

export const MainTabs = [
    { text: 'Log In'},
    { text: 'About', route: '/' },
    { text: 'Media', route: '/media'},
    { text: 'Facebook'},
    { text: 'Twitter'},
    { text: 'Contact Us'},
];

export const LoginTabs = [
    { text: 'My photos', route: '/MyPhotosPage'},
    { text: 'Memos Status', route: '/MemosStatusPage'},
    { text: 'Contact Us', route: '.ContactUsPopup'},
    { text: 'Log Out', route: '/'},
];

export const MobileTabs = [
    { text: 'Log Out', route: '/'},
];

export const MyPhotosTabs = [
    { 
        id: '1',
        icon: 'sorted', 
        text: 'Sort By',
        parent: '0',
        items: [
            {id: '1.4', text: 'Date', parent: '1'},
            {id: '1.6', text: 'Lot', parent: '1'},
            {id: '1.5', text: 'Carat', parent: '1'},
            {
                text: 'Order Direction', 
                items: [
                    {id: '1.7.1', text: 'Descending', parent: '1.7'},
                    {id: '1.7.2', text: 'Ascending', parent: '1.7'}
                ]
            }
        ]
    },
    { 
        id: '2',
        icon: 'smalliconslayout', 
        text: 'Resize Images', 
        parent: '0',
        items: [
            {id: '2.1', text: 'Small', value:'136px', parent: '2'}, 
            {id: '2.2', text: 'Medium', value: '315px', parent: '2'}, 
            {id: '2.3', text: 'Large', value: '456px', parent: '2'}
        ]
    },
    { 
        id: '3',
        icon: 'expand', 
        text: 'Detail Options',
        parent: '0', 
        items: [
            {id: '3.1', text: 'Compact', parent: '3'}, 
            {id: '3.2', text: 'Extended', parent: '3'}
        ]
    },
    { 
        id: '4',
        icon: 'export', 
        text: 'Export csv',
        parent: '0',
        items: [
            {id: '4.1', text: 'All', parent: '4'}, 
            {id: '4.2', text: 'Last Week', parent: '4'},
            {id: '4.3', text: 'Last Month', parent: '4'},
            {id: '4.4', text: 'Last Year', parent: '4'}
        ]
    },
];

export const MobilePhotosTabs = [
    { 
        id: '1',
        icon: 'sorted', 
        text: 'Sort By',
        parent: '0',
        items: [
            {id: '1.4', text: 'Date', parent: '1'},
            {id: '1.6', text: 'Lot', parent: '1'},
            {id: '1.5', text: 'Carat', parent: '1'},
            {
                text: 'Order Direction', 
                items: [
                    {id: '1.7.1', text: 'Descending', parent: '1.7'},
                    {id: '1.7.2', text: 'Ascending', parent: '1.7'}
                ]
            }
        ]
    },
    { 
        id: '4',
        icon: 'export', 
        text: 'Export csv',
        parent: '0',
        items: [
            {id: '4.1', text: 'All', parent: '4'}, 
            {id: '4.2', text: 'Last Week', parent: '4'},
            {id: '4.3', text: 'Last Month', parent: '4'},
            {id: '4.4', text: 'Last Year', parent: '4'}
        ]
    },
];

export const MemoStatus=[
    { text: 'Open', value: 20 },
    { text: 'Closed', value: 100 }
];

export const MemoBranch=[
    { text: 'Ramat Gan', value: 1 },
    { text: 'New York', value: 2 },
    { text: 'Mumbai', value: 3 },
    { text: 'India IGI', value: 4 },
    { text: 'Herzliya', value: 5 },
    { text: 'Hong Kong', value: 6 },
    { text: 'Belgium', value: 7 },
    { text: 'Akron', value: 8 },
    { text: 'Botswana', value: 9 },
    { text: 'Surat', value: 10 },
    { text: 'Seepz', value: 11 },
    { text: 'Dubai', value: 12 }
];

export const StoneStatus = [
    { text: 'Deleted', id: 0},
    { text: 'Waiting For Arrival', id: 10},
    { text: 'Pending', id: 50},
    { text: 'Waiting For Pickup', id: 75},
    { text: 'Returned', id: 100}
];

export const Main3dImage = [
    { id: 'VPT892KIDK'},
    { id: 'IVD1Z8AGLN'},
    { id: 'V1MV164A1A'},
    { id: 'Q3J8P5OZ99'},
    { id: 'N2W0FPJ9QJ'}
];

export const MediaNewsItems=[
    { 
        itemNumber: '4', 
        date: 'July 26 2016', 
        title: 'Segoma - selected as one of the 3 leaders in the Diamonds industry', 
        description: 'Now in "Globes. Israel\'s business arena"', 
        link: 'http://www.globes.co.il/news/article.aspx?did=1001140145'
    },
    // { 
    //     itemNumber: '4', 
    //     date: 'July 11 2016', 
    //     title: 'You can now photograph 1200 diamonds a day!', 
    //     description: 'Thanks to the constant growth in the demand for Segoma´s services, the significant increase in the number of our employees and the enhancement of our equipment, we are expanding our branch in Ramat Gan, Israel. Better service, shorter schedules and an amazing throughput of 1200 photographs a day is what you can expect from Segoma now.Come visit us and see for yourself.', 
    //     link: 'http://segoma.com/contact.php'
    // },
    { 
        itemNumber: '3', 
        date: 'June 1 2016', 
        title: 'Bracelets Also Deserve Segoma', 
        description: 'In light of the great success of the Segoma technology photographing diamonds at 360 degrees, the company is expanding its services for the R2net platform taking also pictures of bracelets.', 
        link: 'https://www.jamesallen.com/fine-jewelry/bracelets/14k-white-gold-diamond-channel-bangle-2-025-ct-tw-item-55807'
    },
    { 
        itemNumber: '2', 
        date: 'May 24 2016', 
        title: 'Gem Leadership – The Story of Rio Tinto\'s largest gem diamond', 
        description: 'Diavik Foxfire is the largest gem diamond ever found in north America. Weighing 187.7 carats, it was showcased at the Rio Tinto\'s booth at the International Gem Week hosted by the Israeli Diamonds Institute. Look how impressive it is in this Segoma image :', 
        link: 'http://segoma.com/v.php?type=iframe&id=KUSR0CA00'
    },
    { 
        itemNumber: '1', 
        date: 'April 4 2016', 
        title: 'Collaboration between Israeli Diamonds Institute and James Allen', 
        description: 'James Allen and the Israeli Diamonds Institute recently announced the collaboration between the Israeli institution and the leading US jewelry-marketing website. A ceremony was held at the beginning of April, and all photographed diamonds have been uploaded to the website. Needless to say, all these quality photos were taken with Segoma.', 
    }
];

export const MediaArticlesItems=[
    { 
        title: 'Did you pack your bag yourself?', 
        description: 'Passengers are already in their seats, the crew is getting ready for the final check before takeoff, and you, who have been working in the diamonds industry for years, are on your way back from another business trip to India carrying several valuable....',
        text: ["Passengers are already in their seats, the crew is getting ready for the final check before takeoff, and you, who have been working in the diamonds industry for years, are on your way back from another business trip to India carrying several valuable stones. The plane takes off and reaches its maximum altitude. It’s time to drink a glass of wine or whiskey, sit comfortably and sleep for a few hours. Sounds familiar? Well, this is not always the case…",
               "",
        " Recently, the Israeli Police apprehended a veteran flight attendant who, based on allegations, put a sleeping pill in the drink of a passenger. While the latter was sleeping, the attendant looked in his bag, which was in the overhead compartment, and stole jewelry and cash. Luckily for this particular passenger, another flight attendant that noticed the thief notified the captain and the flight’s service manager, and upon landing the thief was taken by the police for investigation and confessed to dozens of cases in which he stole goods from passengers.",
               " This is just one example. Diamond robberies do not always take place in action scenes, with robbers wearing masks shooting at the police, and with car chases that inspired so many blockbusters. It can happen all the time, anywhere, quickly, with only one or two persons involved. ",
               " Two such cases were recently documented in the United States. The first was a robbery in a parking lot in Los Angeles. Two masked men stole a bag full of jewels to the tune of $750,000 from a diamond dealer. He was punched on the nose. On another case, a bag of diamonds sent via the US Postal Service for evaluation by the Gemological Institute of America ‘disappeared’ mysteriously, apparently stolen by a postal employee (the investigation is still underway). ",
               " Having to move with diamonds during the trade process is one of the most dangerous stages in any transaction. One of the solutions, beyond the mandatory insurance policy, is taking photographs of the diamonds instead of having to physically transport them. Quality photographs of precious stones enables dealers to obtain a 360-degree view that is accurate and reliable. This not only makes the trade process more efficient, simpler and shorter, but also reduces the risks of the stones being stolen and having to make insurance claims. Segoma’s photography machines, which are today considered the de-facto visual standard for diamonds around the world, serve hundreds of customers in their sales and saves them the need to have to move back and forth with the gems. ",
               " Segoma’s images are based on innovative patented technology that integrates accurate mechanics, unique lighting techniques and high optical capabilities. The high quality of the photographs ensures accuracy, transparency and maximum security in place of having to physically look at and touch the diamonds. ",
               " Undoubtedly, the risk of having diamonds stolen must be taken into consideration when they are physically transported for evaluation by a potential buyer. Therefore, it is advisable, recommended and highly advantageous to consider Segoma’s advanced photography options as the best alternative on the market today. "
    ]
    },
    {  
        title: 'A Picture Is Worth 1000 Words (and Many Miles)', 
        description: '3D photograph and simulation technology are gaining momentum in many fields. Is it a trend? A revolution? A vital need? It doesn’t matter what it is or what we call it, but it is now time for diamonds to join the club. And there is good reason for celebration...', 
        text: `3D photograph and simulation technology are gaining momentum in many fields. Is it a trend? A revolution? A vital need? It doesn’t matter what it is or what we call it, but it is now time for diamonds to join the club. And there is good reason for celebration. 

        In the entertainment world, 3D movies create an experience in which the viewer feels closer to reality than anywhere else. This is achieved by improving the illusion of depth and filming objects from two different perspectives. In the advertising world, 3D pictures of technology products are widely used, simply because products look much better and attractive than in regular photos. Moreover, in the case of specific products, it is very important, essential even, to convey their true image. NASA also uses 3D for its space research with its Mars Rover. And in medicine 3D imaging has also become more and more prevalent in simulations.
        
        In the diamonds world, quality 3D photography offers traders many advantages. First and foremost, security and insurance. Photographs of diamonds obviate the need to physically transport them, thus saving the high costs of transportation, reducing the often unproportioned insurance costs, and also preventing possible robbery from the office, safe or courier service.
        
        Work efficiency is another advantage. 3D imaging of diamonds enables traders to examine them online, with a 360-degree view, accurately and reliably, thus simplifying the trade process. If in the past buyers and sellers used to meet face to face, look at the diamond, touch it and examine it physically, today’s imaging techniques enable to significantly shorten time and save substantially on flights, accommodation and more. In fact, it’s a win-win situation.
        
        Taking 3D pictures of diamonds also enables to work with several potential customers in parallel. Diamond images are sent to several points of sale simultaneously, even if the gems themselves are in a single location, helping the seller to reach as many customers as possible.
        
        Segoma’s advanced photography machines are a great example of how 3D technology has fused into the diamonds world. Segoma specializes in quality automatic imaging solutions that take 360-degree photographs of precious stones. It also provides services to numerous companies worldwide.
        
        Segoma’s advanced photography machines are a great example of how 3D technology has fused into the diamonds world. Segoma specializes in quality automatic imaging solutions that take 360-degree photographs of precious stones. It also provides services to numerous companies worldwide.
        
        Todate, more than 900,000 diamonds have been photographed, appraised and viewed by over six million potential buyers.`
    },
    { 
        title: '360° view of the diamond industry – via Segoma', 
        description: '“Hundreds of thousands of three-dimensional photographs of diamonds have introduced us to some of the new, leading trends in the diamond industry – such as: the types of diamonds preferred by clients, the most popular polishing styles of the year, and the trends characterizing specific markets.”', 
        text: `“Hundreds of thousands of three-dimensional photographs of diamonds have introduced us to some of the new, leading trends in the diamond industry – such as: the types of diamonds preferred by clients, the most popular polishing styles of the year, and the trends characterizing specific markets.”

        The experts at Segoma's cutting-edge diamond photography labs tell us about leading trends in the diamond industry this coming summer.
        
        Diamond photography, like fashion photography, introduces us, the photographers, to the most current trends in the field and teaches us what’s in vogue today in the diamond market, and what is less popular. Each day, we meet the supermodels of the world of diamonds – the most beautiful and expensive stones in the market, alongside hundreds of simpler and smaller diamonds, which also benefit from being shown to best advantage by 360 photography.
        
        Our photographs are published worldwide. Every day, at each of our branches, located in New York, Mumbai and Ramat Gan, we photograph hundreds of precious stones, pieces of jewelry, and of course – diamonds. Our global presence and our experience photographing hundreds of thousands of gems allow us to identify upcoming trends in the diamond market.
        
        One major trend that we have identified is in the field of diamond polishing. If until today we came across mostly standard and accepted cuts (round, emerald and heart-shaped), we have recently seen an emerging trend of innovation and originality in this respect. Cuts are still symmetrical, with round cuts still in the lead, but there is a significant rise in the number of diamonds with a higher or lower number of facets than is standard (58 facets for the familiar round diamond). The pear and oval cuts are leading the trend as unique cuts that reflect more light and we are also seeing an increase in more original cuts, such as stars and crosses.
        
        When it comes to color, white diamonds remain dominant, but there is a significant rise in the number of fancy color diamonds. These stones are measured and graded based on the strength and depth of their color, and therefore they must be polished properly in order to maintain and enhance these qualities, unlike white diamonds, which are cut to reflect as much light as possible. Our unique photography machines capture all of the original characteristics reliably and precisely, revealing the reflection of light from the white stones and the depth of color of the colored stones.
        
        Based on the influx of pink diamonds that were sent to us to be photographed last summer, we expect that this year as well, the summer season will bring with it more intensive sales of pinkhued diamonds. In fact, you could say that each season has its strong colors, with summer characterized by brighter colors, while fall and winter are characterized by deeper colors.
        
        As for the types of diamonds photographed, while until today most of the diamonds photographed have been large ones, today India leads a new trend of photographing masses of`
    },
    {  
        title: 'The digital “Mazal u’Bracha”', 
        description: 'The technological advances that have penetrated every aspect of our lives are gradually entering the world of diamonds. The diamond market, which until recently operated like a traditional business, has been exposed to the world of e-commerce, to new capabilities that significantly ease the process of carrying out transactions, and to the global access that this commerce enables, and it is beginning to integrate advanced technological tools into the diamond trading process.', 
        text:`The technological advances that have penetrated every aspect of our lives are gradually entering the world of diamonds. The diamond market, which until recently operated like a traditional business, has been exposed to the world of e-commerce, to new capabilities that significantly ease the process of carrying out transactions, and to the global access that this commerce enables, and it is beginning to integrate advanced technological tools into the diamond trading process.

        If in the past buyers and sellers used to meet face to face, see the diamond, touch it and fully examine it physically, the virtualization of processes today enables a significant shortcut, and the various technological tools enable precise, high-quality diamond photography and online distribution of photos using digital trade platforms, while saving time and significantly cutting costs.
        
        Segoma’s advanced photography machines constitute but one example of the many means of integrating technology in the diamond industry. The machines are based on innovative patented technology that integrates precise mechanics, unique lighting solutions, and high- resolution optical capabilities. They photograph the diamonds from 360 degrees and produce a result that is precise, sharp, and true to life, without obscuring the flaws and by displaying the exact color, thus enabling a meticulous examination of the stones without needing to physically touch and examine them.
        
        It’s no wonder Segoma is considered the definitive visual standard in the world of diamonds.
        
        The distribution of the pictures has also become virtual, using the electronic diamond trade platform. Segoma’s cameras are directly connected to its trade platform, and enable the pictures to be immediately uploaded and shared on the platform. This capability provides them with exposure to a much larger number of interested parties, including in distant areas of the world, and enables worldwide sales, without geographically dispersed points of sale.
        
        The founders of Segoma are responsible in no small part for the technological revolution in the diamond industry. By virtue of being young, fresh, and connected to current digital trends, they have set themselves a goal of streamlining processes, bringing change to the industry, and taking the world of diamonds a step forward.
        
        As part of its efforts, the team is now working on an additional interesting development, in the form of an internet game that tests diamond dealers’ abilities to compare diamond parameters. The game demonstrates the quality and reliability of Segoma’s photos and enables everyone to experience the process of examining and rating diamonds.
        
        The game, like the rest of the technological possibilities in the diamond trade, upgrades the diamond-purchasing experience, and makes it possible, easy, and accessible from anywhere in the world.`
    }
];

export const MediaVideosItems=[
    { 
        thumbnail: 'https://img.youtube.com/vi/aUa_GUgwbqY/0.jpg', 
        url: 'https://www.youtube.com/embed/aUa_GUgwbqY?si=YJ9-KuAV69RFnGkg'
    },
    {  
        thumbnail: 'https://img.youtube.com/vi/zUl9a1a2KKc/0.jpg',
        url: 'https://www.youtube.com/embed/zUl9a1a2KKc?si=KU77KPob45crpf9f'
    },
    { 
        thumbnail: 'https://img.youtube.com/vi/Eqe1WPEbyx8/0.jpg',
        url: 'https://www.youtube.com/embed/Eqe1WPEbyx8?si=uwWPfq-t-sn7aido'
    },
    {  
        thumbnail: 'https://img.youtube.com/vi/rF8kB3TbXCg/0.jpg',
        url: 'https://www.youtube.com/embed/rF8kB3TbXCg?si=GfgKd8guOPpwUbcG'
    },
    {  
        thumbnail: 'https://img.youtube.com/vi/E1aLgUEyNm4/0.jpg',
        url: 'https://www.youtube.com/embed/E1aLgUEyNm4?si=PbYbxoxGSYeAjI4M'
    },
    {  
        thumbnail: 'https://img.youtube.com/vi/Y-AYkyf0tfQ/0.jpg',
        url: 'https://www.youtube.com/embed/Y-AYkyf0tfQ?si=sdbTbmkbB8g8-tPC'
    },
    {  
        thumbnail: 'https://img.youtube.com/vi/OYZszWfIoL0/0.jpg',
        url: 'https://www.youtube.com/embed/OYZszWfIoL0?si=Fk48AJukwuTCUgSW'
    },
    {  
        thumbnail: 'https://img.youtube.com/vi/t0FsDwqfSiw/0.jpg',
        url: 'https://www.youtube.com/embed/t0FsDwqfSiw?si=87hh9UbpvvYt-RSy'
    }
];

export function getLocaleDateString(){
    var formats = {
       "ar-SA" : "dd/MM/yy",
       "bg-BG" : "dd.M.yyyy",
       "ca-ES" : "dd/MM/yyyy",
       "zh-TW" : "yyyy/M/d",
       "cs-CZ" : "dd.MM.yyyy",
       "da-DK" : "dd-MM-yyyy",
       "de-DE" : "dd.MM.yyyy",
       "el-GR" : "dd/MM/yyyy",
       "en-US" : "MM/dd/yyyy",
       "fi-FI" : "d.M.yyyy",
       "fr-FR" : "dd/MM/yyyy",
       "he-IL" : "dd/MM/yyyy",
       "hu-HU" : "yyyy. MM. dd.",
       "is-IS" : "d.M.yyyy",
       "it-IT" : "dd/MM/yyyy",
       "ja-JP" : "yyyy/MM/dd",
       "ko-KR" : "yyyy-MM-dd",
       "nl-NL" : "d-M-yyyy",
       "nb-NO" : "dd.MM.yyyy",
       "pl-PL" : "yyyy-MM-dd",
       "pt-BR" : "d/M/yyyy",
       "ro-RO" : "dd.MM.yyyy",
       "ru-RU" : "dd.MM.yyyy",
       "hr-HR" : "d.M.yyyy",
       "sk-SK" : "d. M. yyyy",
       "sq-AL" : "yyyy-MM-dd",
       "sv-SE" : "yyyy-MM-dd",
       "th-TH" : "d/M/yyyy",
       "tr-TR" : "dd.MM.yyyy",
       "ur-PK" : "dd/MM/yyyy",
       "id-ID" : "dd/MM/yyyy",
       "uk-UA" : "dd.MM.yyyy",
       "be-BY" : "dd.MM.yyyy",
       "sl-SI" : "d.M.yyyy",
       "et-EE" : "d.MM.yyyy",
       "lv-LV" : "yyyy.MM.dd.",
       "lt-LT" : "yyyy.MM.dd",
       "fa-IR" : "MM/dd/yyyy",
       "vi-VN" : "dd/MM/yyyy",
       "hy-AM" : "dd.MM.yyyy",
       "az-Latn-AZ" : "dd.MM.yyyy",
       "eu-ES" : "yyyy/MM/dd",
       "mk-MK" : "dd.MM.yyyy",
       "af-ZA" : "yyyy/MM/dd",
       "ka-GE" : "dd.MM.yyyy",
       "fo-FO" : "dd-MM-yyyy",
       "hi-IN" : "dd-MM-yyyy",
       "ms-MY" : "dd/MM/yyyy",
       "kk-KZ" : "dd.MM.yyyy",
       "ky-KG" : "dd.MM.yy",
       "sw-KE" : "M/d/yyyy",
       "uz-Latn-UZ" : "dd/MM yyyy",
       "tt-RU" : "dd.MM.yyyy",
       "pa-IN" : "dd-MM-yy",
       "gu-IN" : "dd-MM-yy",
       "ta-IN" : "dd-MM-yyyy",
       "te-IN" : "dd-MM-yy",
       "kn-IN" : "dd-MM-yy",
       "mr-IN" : "dd-MM-yyyy",
       "sa-IN" : "dd-MM-yyyy",
       "mn-MN" : "yy.MM.dd",
       "gl-ES" : "dd/MM/yy",
       "kok-IN" : "dd-MM-yyyy",
       "syr-SY" : "dd/MM/yyyy",
       "dv-MV" : "dd/MM/yy",
       "ar-IQ" : "dd/MM/yyyy",
       "zh-CN" : "yyyy/M/d",
       "de-CH" : "dd.MM.yyyy",
       "en-GB" : "dd/MM/yyyy",
       "es-MX" : "dd/MM/yyyy",
       "fr-BE" : "d/MM/yyyy",
       "it-CH" : "dd.MM.yyyy",
       "nl-BE" : "d/MM/yyyy",
       "nn-NO" : "dd.MM.yyyy",
       "pt-PT" : "dd-MM-yyyy",
       "sr-Latn-CS" : "d.M.yyyy",
       "sv-FI" : "d.M.yyyy",
       "az-Cyrl-AZ" : "dd.MM.yyyy",
       "ms-BN" : "dd/MM/yyyy",
       "uz-Cyrl-UZ" : "dd.MM.yyyy",
       "ar-EG" : "dd/MM/yyyy",
       "zh-HK" : "d/M/yyyy",
       "de-AT" : "dd.MM.yyyy",
       "en-AU" : "d/MM/yyyy",
       "es-ES" : "dd/MM/yyyy",
       "fr-CA" : "yyyy-MM-dd",
       "sr-Cyrl-CS" : "d.M.yyyy",
       "ar-LY" : "dd/MM/yyyy",
       "zh-SG" : "d/M/yyyy",
       "de-LU" : "dd.MM.yyyy",
       "en-CA" : "dd/MM/yyyy",
       "es-GT" : "dd/MM/yyyy",
       "fr-CH" : "dd.MM.yyyy",
       "ar-DZ" : "dd-MM-yyyy",
       "zh-MO" : "d/M/yyyy",
       "de-LI" : "dd.MM.yyyy",
       "en-NZ" : "d/MM/yyyy",
       "es-CR" : "dd/MM/yyyy",
       "fr-LU" : "dd/MM/yyyy",
       "ar-MA" : "dd-MM-yyyy",
       "en-IE" : "dd/MM/yyyy",
       "es-PA" : "MM/dd/yyyy",
       "fr-MC" : "dd/MM/yyyy",
       "ar-TN" : "dd-MM-yyyy",
       "en-ZA" : "yyyy/MM/dd",
       "es-DO" : "dd/MM/yyyy",
       "ar-OM" : "dd/MM/yyyy",
       "en-JM" : "dd/MM/yyyy",
       "es-VE" : "dd/MM/yyyy",
       "ar-YE" : "dd/MM/yyyy",
       "en-029" : "MM/dd/yyyy",
       "es-CO" : "dd/MM/yyyy",
       "ar-SY" : "dd/MM/yyyy",
       "en-BZ" : "dd/MM/yyyy",
       "es-PE" : "dd/MM/yyyy",
       "ar-JO" : "dd/MM/yyyy",
       "en-TT" : "dd/MM/yyyy",
       "es-AR" : "dd/MM/yyyy",
       "ar-LB" : "dd/MM/yyyy",
       "en-ZW" : "M/d/yyyy",
       "es-EC" : "dd/MM/yyyy",
       "ar-KW" : "dd/MM/yyyy",
       "en-PH" : "M/d/yyyy",
       "es-CL" : "dd-MM-yyyy",
       "ar-AE" : "dd/MM/yyyy",
       "es-UY" : "dd/MM/yyyy",
       "ar-BH" : "dd/MM/yyyy",
       "es-PY" : "dd/MM/yyyy",
       "ar-QA" : "dd/MM/yyyy",
       "es-BO" : "dd/MM/yyyy",
       "es-SV" : "dd/MM/yyyy",
       "es-HN" : "dd/MM/yyyy",
       "es-NI" : "dd/MM/yyyy",
       "es-PR" : "dd/MM/yyyy",
       "am-ET" : "d/M/yyyy",
       "tzm-Latn-DZ" : "dd-MM-yyyy",
       "iu-Latn-CA" : "d/MM/yyyy",
       "sma-NO" : "dd.MM.yyyy",
       "mn-Mong-CN" : "yyyy/M/d",
       "gd-GB" : "dd/MM/yyyy",
       "en-MY" : "dd/MM/yyyy",
       "prs-AF" : "dd/MM/yy",
       "bn-BD" : "dd-MM-yy",
       "wo-SN" : "dd/MM/yyyy",
       "rw-RW" : "M/d/yyyy",
       "qut-GT" : "dd/MM/yyyy",
       "sah-RU" : "MM.dd.yyyy",
       "gsw-FR" : "dd/MM/yyyy",
       "co-FR" : "dd/MM/yyyy",
       "oc-FR" : "dd/MM/yyyy",
       "mi-NZ" : "dd/MM/yyyy",
       "ga-IE" : "dd/MM/yyyy",
       "se-SE" : "yyyy-MM-dd",
       "br-FR" : "dd/MM/yyyy",
       "smn-FI" : "d.M.yyyy",
       "moh-CA" : "M/d/yyyy",
       "arn-CL" : "dd-MM-yyyy",
       "ii-CN" : "yyyy/M/d",
       "dsb-DE" : "d. M. yyyy",
       "ig-NG" : "d/M/yyyy",
       "kl-GL" : "dd-MM-yyyy",
       "lb-LU" : "dd/MM/yyyy",
       "ba-RU" : "dd.MM.yy",
       "nso-ZA" : "yyyy/MM/dd",
       "quz-BO" : "dd/MM/yyyy",
       "yo-NG" : "d/M/yyyy",
       "ha-Latn-NG" : "d/M/yyyy",
       "fil-PH" : "M/d/yyyy",
       "ps-AF" : "dd/MM/yy",
       "fy-NL" : "d-M-yyyy",
       "ne-NP" : "M/d/yyyy",
       "se-NO" : "dd.MM.yyyy",
       "iu-Cans-CA" : "d/M/yyyy",
       "sr-Latn-RS" : "d.M.yyyy",
       "si-LK" : "yyyy-MM-dd",
       "sr-Cyrl-RS" : "d.M.yyyy",
       "lo-LA" : "dd/MM/yyyy",
       "km-KH" : "yyyy-MM-dd",
       "cy-GB" : "dd/MM/yyyy",
       "bo-CN" : "yyyy/M/d",
       "sms-FI" : "d.M.yyyy",
       "as-IN" : "dd-MM-yyyy",
       "ml-IN" : "dd-MM-yy",
       "en-IN" : "dd-MM-yyyy",
       "or-IN" : "dd-MM-yy",
       "bn-IN" : "dd-MM-yy",
       "tk-TM" : "dd.MM.yy",
       "bs-Latn-BA" : "d.M.yyyy",
       "mt-MT" : "dd/MM/yyyy",
       "sr-Cyrl-ME" : "d.M.yyyy",
       "se-FI" : "d.M.yyyy",
       "zu-ZA" : "yyyy/MM/dd",
       "xh-ZA" : "yyyy/MM/dd",
       "tn-ZA" : "yyyy/MM/dd",
       "hsb-DE" : "d. M. yyyy",
       "bs-Cyrl-BA" : "d.M.yyyy",
       "tg-Cyrl-TJ" : "dd.MM.yy",
       "sr-Latn-BA" : "d.M.yyyy",
       "smj-NO" : "dd.MM.yyyy",
       "rm-CH" : "dd/MM/yyyy",
       "smj-SE" : "yyyy-MM-dd",
       "quz-EC" : "dd/MM/yyyy",
       "quz-PE" : "dd/MM/yyyy",
       "hr-BA" : "d.M.yyyy.",
       "sr-Latn-ME" : "d.M.yyyy",
       "sma-SE" : "yyyy-MM-dd",
       "en-SG" : "dd/MM/yyyy",
       "ug-CN" : "yyyy-M-d",
       "sr-Cyrl-BA" : "d.M.yyyy",
       "es-US" : "MM/dd/yyyy"
    };
       return formats[navigator.language] || 'dd/MM/yyyy';
}