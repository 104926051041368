import { SITE_URL } from './app-globalVariables';
import { createContext, useState, useEffect } from 'react';

const UserContext = createContext();
let didInit = false;

export const UserProvider = ({ children }) => {
  const [userSession, setUserSession] = useState(undefined);

  const updateUserSession = (userSessionToUpdate) => {
    setUserSession(userSessionToUpdate);
  };

  useEffect(() => {
    // Only runs once per app load
    const fetchData = async () => {
        try {
          const response = await fetchUserData();
          const resUserSession = await response.json();
          if (typeof resUserSession === 'object')
            updateUserSession(resUserSession);
          else
            updateUserSession({id: -1});
        } 
        catch (error) {
          console.error('Error fetching user data', error);
        }
      };
    
    if (!didInit) {
      didInit = true;
      fetchData();
    }
  }, []);

  const fetchUserData = async () => {
    return await fetch(SITE_URL + "GetUserData?", {
        method: "POST",
        credentials: "include",
        headers: {
        'Accept': "application/json",
        "Content-Type": "application/json",
        },
    });
  };

  return (
    <UserContext.Provider value={{userSession, updateUserSession}}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
