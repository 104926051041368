import 'devextreme/dist/css/dx.light.css';
import React, {Suspense, lazy, useContext, useMemo} from 'react';
import {SITE_URL} from '../src/app-globalVariables';
//import MainNavigationBar from '../src/Components/MainNavigationBar/MainNavigationBar';
import notify from "devextreme/ui/notify";
import { Routes, Route } from 'react-router-dom';
import UserContext from './UserContext';
import './App.css';

const LazyMainNavigationBar = lazy(() => import('../src/Components/MainNavigationBar/MainNavigationBar'));
const LazyMyPhotosPage = lazy(() => import('./Pages/MyPhotosPage/MyPhotosPage'));
const LazyMediaPage = lazy(() => import('./Pages/MediaPage/MediaPage'));
const LazyMemosStatusPage = lazy(() => import('./Pages/MemosStatusPage/MemosStatusPage'));
const LazyResetPasswordPage = lazy(() => import('./Pages/ResetPasswordPage/ResetPasswordPage'));
const LazyETagGuidePage = lazy(() => import('./Pages/ETagGuidePage/ETagGuide'));
const LazyETagInfoPage = lazy(() => import('./Pages/ETagInfoPage/ETagInfo'));
const LazyETagRegulatoryPage = lazy(() => import('./Pages/ETagRegulatoryPage/ETagRegulatory'));
const LazyETagTroubleShootingPage = lazy(() => import('./Pages/ETagTroubleShootingPage/ETagTroubleShooting'));
const LazyAboutPage = lazy(() => import('./Pages/AboutPage/AboutPage'));

const App = () => {
  const {userSession} = useContext(UserContext);

  useMemo(() => GetWebServiceVersion(),[]);

  function GetWebServiceVersion() {
    //get the WebServiceVersion from the server to display in the about section
    return fetch(SITE_URL + "GetWebServiceVersion", {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    })
    .then((res) => {
      if (!res.ok) throw res;
      else return res.json();
    })
    .then((res) => {console.log("Web Service Version -", res);console.log("Client Version - 2.0.0.0");})
    .catch(function (error) {
      notify(
        { message: "Error has occurred", width: 500 },
        "error"
      );
      console.log("Request failed -", error);
    });
  }

  return(
    (userSession !== undefined) &&
    <div className='main'>
       {userSession.id !== -2 && <div className='banner'>
        <img style={{height: "100%"}} src ="https://segoma.com/clientview/media/logo_dark.png" alt = "" />
        {/* user connected with a first name */}
        {userSession.firstName !== null && userSession.id !== -3 && userSession.id !== -1 && <label className='nameLabel'>{'Hello '} {userSession.firstName}{', '} {userSession.clientName}</label>}
        {/* user connected without first name */}
        {userSession.firstName === null && userSession.id !== -3 && userSession.id !== -1 && <label className='nameLabel'>{'Hello'}{', '} {userSession.clientName}</label>}
        {userSession.id !== -3 && <LazyMainNavigationBar className='navBar'></LazyMainNavigationBar>}
      </div>}
        <Routes>
          <Route path = '/' element={<Suspense fallback={<div></div>}> {userSession.id !== -1 ?  <LazyMyPhotosPage /> : <LazyAboutPage />} </Suspense>} />
          <Route path='/media' element={<Suspense fallback={<div></div>}> <LazyMediaPage /></Suspense>} />
          <Route path = '/MemosStatusPage' element={<Suspense fallback={<div></div>}> <LazyMemosStatusPage /> </Suspense>} />
          <Route path = '/MyPhotosPage' element={<Suspense fallback={<div></div>}> <LazyMyPhotosPage /> </Suspense>} />
          <Route path = '/ResetPassword' element={<Suspense fallback={<div></div>}> <LazyResetPasswordPage /> </Suspense>} />
          <Route path='/ETagGuide' element={<Suspense fallback={<div></div>}> <LazyETagGuidePage /></Suspense>} />
          <Route path='/EtagInfo' element={<Suspense fallback={<div></div>}> <LazyETagInfoPage /></Suspense>} />
          <Route path='/EtagRegulatory' element={<Suspense fallback={<div></div>}> <LazyETagRegulatoryPage /></Suspense>} />
          <Route path='/ETagTroubleShooting' element={<Suspense fallback={<div></div>}> <LazyETagTroubleShootingPage /></Suspense>} />
          {/* <Route path='*' element={<Suspense fallback={<div></div>}> {userSession.id !== -1 ?  <LazyMyPhotosPage /> : <LazyAboutPage />} </Suspense>} /> */}
        </Routes>
    </div>
  );

}
export default App;

